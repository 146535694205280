import './Preguntas.css';
import React from 'react';

function Preguntas() {
  return (
    <div className="preguntas-container">
      <div className="tarjeta">
        <h3>¿Cómo comprar?</h3>
        <hr />
        <p>Para realizar una compra online siga nuestro video tutorial en el siguiente link</p>
      </div>
      <div className="tarjeta">
        <h3>¿Cuáles son los horarios de atención?</h3>
        <hr />
        <p>Los horarios de atención son <strong>de lunes a viernes</strong> de 8:30 a 12:30hs // 15:30 a 19:30 <br /> y <strong>sábados </strong> de 9 a 13hs.</p>
      </div>
      <div className="tarjeta">
        <h3>¿Aceptan tarjetas de crédito/débito?</h3>
        <hr />
        <p>Aceptamos visa, visa débito, mastercard, mastercard debit, maestro y naranja.<br /> Para conocer planes y financiación por favor contáctenos utilizando el botón de Whatsapp de esta misma página.</p>
      </div>
    </div>
  );
}

export default Preguntas;
