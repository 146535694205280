import React, { useEffect, useState } from 'react';
import '../Company/Company.css';
import Modal from '../Modal/Modal.jsx';

function Popular() {
  const list = ["Administrativo", "Productivo", "Hospitalario", "Limpieza"];
  const [name] = useState(() => list[Math.floor(Math.random() * list.length)]); // Selecciona un nombre aleatorio solo una vez
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const imageBasePath = `https://creacionesperla.com/Assets/Empresas/${name}`;

  useEffect(() => {
    fetch(`https://creacionesperla.com/api/uniformesempresas/${name}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const randomProducts = data.sort(() => 0.5 - Math.random()).slice(0, 6);
        setProducts(randomProducts);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [name]);

  const openModal = (product) => {
    setSelectedProduct(product);
  };
  
  const closeModal = () => {
    setSelectedProduct(null);
  };

  const handleImageError = (e) => {
    if (e.target.src.endsWith('.webp')) {
      // Si falla la carga de la imagen .webp, intenta con .jpg
      e.target.src = e.target.src.replace('.webp', '.jpg');
    } else if (e.target.src.endsWith('.jpg')) {
      // Si falla la carga de la imagen .jpg, intenta con .png
      e.target.src = e.target.src.replace('.jpg', '.png');
    }
  };

  return (
    <div className="company-container">
      <h2>Ya conocías nuestra línea de productos empresariales para el sector {name}?</h2>
      <div className="products-container">
        {products.map((product, index) => (
          <div 
            key={index} 
            className="product-card"
            onClick={() => openModal(product)}
          >
            <h3>{product.Nombre}</h3>
            <img 
              src={`${imageBasePath}/${product.Imagen}.webp`} 
              alt={product.Nombre} 
              className="product-image"
              loading="lazy"
              onError={handleImageError}
            />
          </div>
        ))}
      </div>
      {selectedProduct && (
        <Modal product={selectedProduct} onClose={closeModal} imageBasePath={imageBasePath} />
      )}
    </div>
  );
}

export default Popular;

