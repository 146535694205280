import React, { useContext, useEffect, useState } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContex';
import remove_icon from '../Assets/cart_cross_icon.png';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';


export const CartItems = () => {
    const {cartItems, setCartItems, removeFromCart, getTotalCartAmount, setDeliveryCost, customerType, setPaymentMethod, interest, deliveryMethod, setDeliveryMethod, getDeliveryCost, updateComments, comments, isCartEmpty, deliveryOptions,deliveryCost} = useContext(ShopContext);
    const [metodos, setMetodos] = useState([]);
    const [paymentMethodMap, setPaymentMethodMap] = useState({});

    const formatText = (text) => {
        return text.replace(/\(([^)]+)\)/g, '<span class="highlight">($1)</span>');
    };

    useEffect(() => {
        fetch('https://creacionesperla.com/api/metodos-pago-all')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setMetodos(data);
    
                // Transformar metodos en un mapa dinámico
                const paymentMap = data.reduce((map, metodo) => {
                    map[metodo.Nombre_Metodo.toLowerCase()] = {
                        method: metodo.Descripcion_Metodo,
                        interest: metodo.Interes_Metodo,
                    };
                    return map;
                }, {});
                setPaymentMethodMap(paymentMap); // Actualizar el mapa dinámico
            })
            .catch(error => console.error('Error fetching métodos de pago:', error));
    }, []);

    const handlePaymentChange = (e) => {
        const { id } = e.target;
        const selectedPaymentMethod = paymentMethodMap[id];
        if (selectedPaymentMethod) {
            setPaymentMethod({ ...selectedPaymentMethod, id });
        } else {
            console.warn(`Método de pago con id "${id}" no encontrado en paymentMethodMap.`);
        }
    };

    // useEffect(() => {
    //     fetch('https://creacionesperla.com/api/metodos-pago-all')
    //       .then(response => {
    //         if (!response.ok) {
    //           throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //       })
    //       .then(data => setMetodos(data))
    //       .catch(error => console.error('Error fetching colegios:', error));
    //   }, []);


    const handleDeliveryChange = (e) => {
        setDeliveryMethod(e.target.value);
    };

    useEffect(() => {
        const deliveryCost = getDeliveryCost(deliveryMethod);
        setDeliveryCost(deliveryCost);
    }, [deliveryMethod, cartItems, setDeliveryCost, getTotalCartAmount, getDeliveryCost]);

    const handleQuantityChange = (type, itemId) => {
        setCartItems((prevCartItems) => {
            const updatedCartItems = { ...prevCartItems };
            const item = updatedCartItems[itemId];

            if (type === 'inc') {
                item.quantity += 1;
            } else if (type === 'dec' && item.quantity > 1) {
                item.quantity -= 1;
            }

            return updatedCartItems;
        });
    };

    // const paymentMethodMap = {
    //     'efectivo': { method: 'Efectivo', interest: 0 },
    //     'debito': { method: 'Débito', interest: 0 },
    //     'credito-1': { method: 'Tarjeta de crédito (1 pago)', interest: 0 },
    //     'credito-3': { method: 'Tarjeta de crédito (3 pagos)', interest: 0.15 },
    //     'naranja': { method: 'Tarjeta Naranja: Plan Z', interest: 0.10 }
    // };

    // const handlePaymentChange = (e) => {
    //     const { id } = e.target;
    //     const selectedPaymentMethod = paymentMethodMap[id];
    //     setPaymentMethod({ ...selectedPaymentMethod, id });
    // };

    const handleCommentsChange = (e) => {
        updateComments(e.target.value); // Actualizar comentarios en el contexto
    };

    const [customerInfo, setCustomerInfo] = useState({
        institution: '',
        name: '',
        surname: '',
        email: '',
        telephone: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo({
            ...customerInfo,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!customerInfo || !customerInfo.name || !customerInfo.telephone || !cartItems) {
            alert('Por favor, complete toda la información requerida.');
            return;
        }
    
        const timestamp = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'); // Genera el timestamp en la zona horaria de Argentina
    
        // Obtener información de productos del carrito
        const orderDetails = Object.entries(cartItems).map(([itemId, item]) => ({
            itemId,
            name: item.name,
            quantity: item.quantity,
        }));
    
        const data = {
            customerInfo,
            orderDetails,
            comments,
            timestamp
        };
    
        console.log('Submitting order data:', data); // Log the data being enviado
    
        try {
            const response = await fetch('https://creacionesperla.com/api/save-company-order', { // Asegurarse de que la URL esté correcta
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                alert('Gracias por su pedido, en breve nos pondremos en contacto con usted. Por favor, verifique que ha recibido el email con su orden. Revise su casilla de spam en caso de no encontrarlo.');
                window.location.href = '/'; // Redirigir a la página principal
            } else {
                const errorData = await response.json();
                alert(`Error desde la base de datos: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al finalizar la compra');
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);

    // Función para manejar clic en la imagen
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    // Función para cerrar la vista ampliada
    const handleCloseImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className='cartitems'>
             <div className='customer-type'>Tipo de compra: {customerType}</div>
            <table className="cartitems-format-main">
                <thead>
                    <tr>
                        <th className="hide-on-small">Imagen</th>
                        <th>Nombre Producto</th>
                        <th>Talle</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th className="hide-on-small">Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(cartItems).map((key) => {
                        const item = cartItems[key];
                        if (item.quantity > 0) {
                            return (
                                <tr key={key}>
                                    <td className="hide-on-small">
                                        <img src={item.image} 
                                        alt={item.name} 
                                        className='carticon-product-icon'
                                        onClick={() => handleImageClick(item.image)}
                                        style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.talle === "Kit" ? (
                                            Object.keys(item.kit_talles).map((productName) => (
                                                <div className='talles-kits' key={productName}>
                                                    <strong>{productName}:</strong> {item.kit_talles[productName]}
                                                </div>
                                            ))
                                        ) : (
                                            item.talle
                                        )}
                                    </td>
                                    <td>${item.price}</td>
                                    <td>
                                        <div className="pro-qty">
                                            <span className="dec qtybtn" onClick={() => handleQuantityChange('dec', key)}>-</span>
                                            <input type="text" value={item.quantity} disabled />
                                            <span className="inc qtybtn" onClick={() => handleQuantityChange('inc', key)}>+</span>
                                        </div>
                                    </td>
                                    <td>${item.price * item.quantity}</td>
                                    <td className="hide-on-small"><img className='cartitems-remove-icon' src={remove_icon} alt="" onClick={() => { removeFromCart(key); }} /></td>
                                </tr>
                            );
                        }
                        return null;
                    })}
                </tbody>
            </table>
            {selectedImage && (
                <div className="modal-overlay" onClick={handleCloseImage}>
                    <div className="modal-content">
                        <img className="modal-image" src={selectedImage} alt="Imagen ampliada" />
                        <div className="modal-buttons">
                            <button className="close-button" onClick={handleCloseImage}>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}
            <div className={`cartitems-down ${customerType !== 'uniformes escolares' ? 'hidden' : ''}`}>
                <div className="cartitems-delivery">
                    <h3>¿A dónde te gustaría que enviemos tu pedido?</h3>
                    <form>
                        {deliveryOptions.map(option => (
                            <label key={option.Id_Envio}>
                                <input
                                    type="radio"
                                    name="Delivery"
                                    value={option.Nombre_Envio}
                                    checked={deliveryMethod === option.Nombre_Envio}
                                    onChange={handleDeliveryChange}
                                />
                                <span dangerouslySetInnerHTML={{ __html: formatText(option.Descripcion_Envio) }} />
                            </label>
                        ))}
                    </form>
                </div>
                <div className="cartitems-promocode">
                    <h3>Si tenés un código de descuento, podés ingresarlo acá</h3>
                    <div className="cartitems-promobox">
                        <input type="text" placeholder='Código de descuento' />
                    </div>
                    <br />
                    <button className="cartitems-promobox-button">Aplicar descuento</button>
                </div>
            </div>
            <div className={`cartitems-down ${customerType !== 'uniformes escolares' ? 'hidden' : ''}`}> 
                <div className="payment-method">
                    <h3>Método de Pago</h3>
                    <p className="payment-method-clarify"><strong>No recibimos pagos a través de la web. El pago se realiza al recibir el producto</strong></p>
                    {metodos.map(metodo => (
                        <div key={metodo.Id_Metodo}>
                            <input
                                type="radio"
                                id={metodo.Nombre_Metodo}
                                name="paymentMethod"
                                value={metodo.Interes_Metodo}
                                onChange={handlePaymentChange}
                            />
                            <label htmlFor={metodo.Nombre_Metodo}>{metodo.Descripcion_Metodo} ({metodo.Interes_Metodo*100}%)</label>
                        </div>
                    ))}
                </div>
                <div className="cartitems-comments">
                    <h3>¿Querés dejarnos algún comentario sobre las prendas?</h3>
                    <label htmlFor="comments"></label>
                    <textarea onChange={handleCommentsChange} id="comments" rows="5" placeholder='Acá podés indicarnos tus especificaciones sobre las prendas, la tela que estás buscando o tus horarios para recibir el paquete'></textarea>
                </div>
            </div>
            <div className={`cartitems-down ${customerType !== 'uniformes escolares' ? 'hidden' : ''}`}>
                <div className="cartitems-total">
                    <h1>Total del carrito</h1>
                    <div>
                        <div className="caritems-total-item">
                            <p>Subtotal</p>
                            <p>${getTotalCartAmount()}</p>
                        </div>
                        <hr />
                    </div>
                    <div>
                        <div className="caritems-total-item">
                            <p>Intereses</p>
                            <p>${interest.toFixed(2)}</p>
                        </div>
                        <hr />
                        <div className="caritems-total-item">
                            <h3>Total (prendas + intereses)</h3>
                            <h3>${(getTotalCartAmount() + interest).toFixed(2)}</h3>
                        </div>
                        <hr />
                    </div>
                    <div className="caritems-total-item">
                        <p>Costo envío <br />(será abonado en efectivo)</p>
                        <p>${deliveryCost}</p>
                    </div>
                    <hr />
                    <Link disabled={isCartEmpty()} to={`/cart-form/`} onClick={() => window.scrollTo(0, 0)}>
                        <button disabled={isCartEmpty()}>Confirmar compra</button>
                    </Link>
                </div>
            </div>
            <div className={`cartitems-down ${customerType !== 'uniformes empresariales' ? 'hidden' : ''}`}>
                        <form className="cartitems-client-data" onSubmit={handleSubmit}>
                            <h2>Datos de contacto</h2>
                            <div>
                                <label>Institución/Empresa a la que pertenece*</label>
                                <input
                                type="text"
                                id="institution"
                                name="institution"
                                value={customerInfo.institution}
                                onChange={handleInputChange}
                                required
                                />
                            </div>
                            <div>
                                <label>Nombre*</label>
                                <input
                                type="text"
                                id="name"
                                name="name"
                                value={customerInfo.name}
                                onChange={handleInputChange}
                                required
                                />
                            </div>
                            <div>
                                <label>Apellido*</label>
                                <input
                                type="text"
                                id="surname"
                                name="surname"
                                value={customerInfo.surname}
                                onChange={handleInputChange}
                                required
                                />
                            </div>
                            <div>
                                <label>Email*</label>
                                <input
                                type="email"
                                id="email"
                                name="email"
                                value={customerInfo.email}
                                onChange={handleInputChange}
                                required
                                />
                            </div>
                            <div>
                                <label>Telefono de contacto*</label>
                                <input
                                type="text"
                                id="telephone"
                                name="telephone"
                                value={customerInfo.telephone}
                                onChange={handleInputChange}
                                required
                                />
                            </div>
                            <div>
                                <label>Mensaje</label>
                                <textarea onChange={handleCommentsChange} id="comments" name="mensaje-presupuesto" rows="5" placeholder='Acá podés indicarnos si necesitas que bordemos algún nombre, especificaciones sobre la tela que estás buscando, etc.'></textarea>
                            </div>
                            <button type="submit" disabled={isCartEmpty()}>Solicitar presupuesto</button>
                        </form>
            </div>
        </div>
    );
};

export default CartItems;











