import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import Shop from './Pages/Shop';
import Schools from './Pages/Schools';
import Cart from './Pages/Cart';
import CartForm from './Components/CartForm/CartForm';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import Niveles from './Components/Niveles/Niveles';
import Gender from './Components/Gender/Gender';
import Identidad from './Components/Identidad/Identidad';
import Contacto from './Components/Contacto/Contacto';
import Preguntas from './Components/Preguntas/Preguntas';
import Companies from './Pages/Companies';
import Company from './Components/Company/Company';
import Kits from './Components/Kits/Kits';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Shop/>}/>
        <Route path='/escolar' element={<Schools category="escolar"/>}/>
        <Route path="/escolar/:id" element={<Niveles/>} />
        <Route path="/escolar/:id/:nivel/:sexo" element={<Gender/>} />
        <Route path="/escolar/:id/kit/:sexo" element={<Kits/>} />
        <Route path="/identidad" element={<Identidad/>} />
        <Route path="/contacto" element={<Contacto/>} />
        <Route path="/preguntas" element={<Preguntas/>} />
        <Route path="/empresas" element={<Companies/>} />
        <Route path="/empresas/:name" element={<Company/>} />
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/cart-form' element={<CartForm/>}/>
        <Route path='/login' element={<LoginSignup/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
