import React from 'react'
import './NewsLetter.css'

const NewsLetter = () => {
  return (
    <div className='newsletter'>
        <h1>Recibí ofertas exclusivas y descuentos en tu email</h1>
        <p>Suscribite a nuestro programa de clientes y descubrí todos los beneficios que tenemos para vos</p>
        <div className='input-container'>
            <input type="email" placeholder='Email de contacto' />
            <button>Suscribirse</button>
        </div>
    </div>
  )
}
export default NewsLetter