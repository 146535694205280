import React, { useContext } from 'react';
import './Modal.css';
import { ShopContext } from '../../Context/ShopContex';

function Modal({ product, onClose, imageBasePath }) {
  const { addToCart, addcustomerType } = useContext(ShopContext);

  const handleAddToCart = () => {
    addcustomerType('uniformes empresariales');
    addToCart(
        product.IdProductoEmpresa, // ID del producto
        product.Nombre, // Nombre del producto
        product.Precio || 0, // Precio del producto
        1, // Cantidad
        `${imageBasePath}/${product.Imagen}.jpeg`, // Ruta de la imagen
        product.Talle || 'nulo' // Talle (vacío si no está definido)
      );
    alert('Producto añadido al carrito');
    onClose();
  };
  const handleImageError = (e) => {
    if (e.target.src.endsWith('.webp')) {
      // Si falla la carga de la imagen .webp, intenta con .jpg
      e.target.src = e.target.src.replace('.webp', '.jpg');
    } else if (e.target.src.endsWith('.jpg')) {
      // Si falla la carga de la imagen .jpg, intenta con .png
      e.target.src = e.target.src.replace('.jpg', '.png');
    }
  }; 

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>{product.Nombre}</h3>
        <img 
          src={`${imageBasePath}/${product.Imagen}.jpeg`} 
          alt={product.Nombre} 
          className="modal-image"
          loading="lazy"
          onError={handleImageError}
        />
        <p className="modal-description">{product.Descripcion}</p>
        <div className="modal-buttons">
          <button className="quote-button" onClick={handleAddToCart}>
            Solicitar cotización
          </button>
          <button className="close-button" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;


