import './School.css';
import { Link } from 'react-router-dom';
import React from 'react';

const School = ({ id, name, imagen }) => {
    // Ruta base donde se encuentran las imágenes
    const imageBasePath = 'https://creacionesperla.com/Assets/';

    // Función para manejar errores de carga y probar distintos formatos de imagen
    const handleImageError = (e) => {
        if (e.target.src.endsWith('.webp')) {
            // Si falla la carga de la imagen .webp, intenta con .jpg
            e.target.src = `${imageBasePath}${imagen}.jpg`;
        } else if (e.target.src.endsWith('.jpg')) {
            // Si falla la carga de la imagen .jpg, intenta con .png
            e.target.src = `${imageBasePath}${imagen}.png`;
        }
    };

    return (
        <div className='school'>
            <Link to={`/escolar/${id}`} onClick={() => window.scrollTo(0, 0)}>
                <img 
                    src={`${imageBasePath}${imagen}.webp`} 
                    alt="Descripcion" 
                    loading="lazy" 
                    onError={handleImageError} // Llama a la función handleImageError en caso de error
                />
            </Link>
            <p>{name}</p>
        </div>
    );
};

export default School;
