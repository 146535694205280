import './Identidad.css';
import React from 'react';
import blogDetailImage from '../Assets/imagen-empresa.webp';

function Identidad() {
  return (
    <div className="identidad-container">
      <div className="identidad-image">
        <img src={blogDetailImage} alt="Imagen de la Empresa" />
      </div>
      <div className="identidad-content">
        <div className="identidad-section">
          <h2>Historia</h2>
          <p>La historia de esta empresa comienza a mediados de la década del 50 como un pequeño negocio en un incipiente barrio ferroviario que luego se llamaría Alta Córdoba. Los emprendedores de aquellos tiempos son abuelos o bisabuelos de las últimas generaciones que hoy trabajan en la empresa.</p>
          <p>Con la siguiente generación familiar, este emprendimiento se fue adaptando al mercado del barrio. Adoptaron como base de su crecimiento los créditos en cuenta corriente a sus vecinos, lo que era el símbolo de la confianza en ellos: la libreta de cuenta, en la que, sin mediar más que la "confianza" en uno y "la palabra" en el otro, se anotaba todo lo que el cliente-amigo quisiera llevarse.</p>
          <p>Luego, ya transitando tiempos más recientes, dados los devenires de la economía de nuestro país y el crecimiento logrado, con nuevos impulsos de nuevas generaciones, se trató de crecer en el mercado de los uniformes. Esto se hizo en base a la experiencia ganada y pensando en mantener tanto a la clientela que siempre nos acompañó como, fundamentalmente, a los colaboradores que siempre nos apoyaron y que forman parte de nuestra familia.</p>
          <p>Brindamos lo mejor de nuestros conocimientos para tratar de ser hoy mejores que ayer y mañana mejores que hoy.</p>
        </div>
        <div className="identidad-section">
          <h2>Misión</h2>
          <p>Empleamos insumos de primera, respetando estándares de calidad en nuestros procesos para garantizar la eficiencia y durabilidad de las prendas.</p>
        </div>
        <div className="identidad-section">
          <h2>Visión</h2>
          <p>Posicionarnos como lideres en la elaboración y comercialización de uniformes en el mercado nacional e internacional.</p>
        </div>
        <div className="identidad-section">
          <h2>Valores</h2>
          <p>Honestidad, calidad y flexibilidad</p>
        </div>
      </div>
    </div>
  );
}

export default Identidad;
