import './Kits.css';
import { ShopContext } from '../../Context/ShopContex';
import { useParams, Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';

function Kits() {
    const { id, sexo } = useParams();
    const [kits, setKits] = useState([]);
    const [sizes, setSizes] = useState({}); // Estado para almacenar los talles de cada producto
    const { addToCart, addcustomerType } = useContext(ShopContext);
    const [quantity, setQuantity] = useState(1);
    const [selectedSizes, setSelectedSizes] = useState({}); // Estado para almacenar los talles seleccionados
    const imageBasePath = 'https://creacionesperla.com/Assets/Escuelas/';
    const imageBasePathinfo = 'https://creacionesperla.com/Assets/information.png';
    const [zona, setZona] = useState('');
    const [colegio, setColegio] = useState('');

    useEffect(() => {
        fetch(`https://creacionesperla.com/api/ubicacion-colegio/${id}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data.length > 0) {
              setZona(data[0].Zona);  // Accede a la propiedad 'Zona' del primer resultado
              setColegio(data[0].Nombre_Colegio);
            } else {
              setZona('No disponible'); // Manejar el caso cuando no haya resultados
              setZona('Nombre no disponible');
            }
          })
          .catch(error => console.error('Error fetching colegios:', error));
    }, [id]);

    useEffect(() => {
        const fetchKits = async () => {
            try {
                const response = await fetch(`https://creacionesperla.com/api/kits-products?Id_Colegio=${id}&sexo=${sexo}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const kitsList = await response.json();
                console.log("kits recuperados:", kitsList);
                setKits(kitsList);
    
                // Luego de cargar los kits, buscar los talles de cada producto
                kitsList.forEach(kit => {
                    for (let i = 0; i < 15; i++) {
                        const nombreProducto = kit[`Nombre_Producto_${i + 1}`];
                        if (nombreProducto) {
                            fetchProductSizes(nombreProducto, kit.Id_Kit);
                        }
                    }
                });

            } catch (error) {
                console.error('Error fetching kits:', error);
            }
        };

        const fetchProductSizes = async (nombreProducto, kitId) => {
            try {
                const response = await fetch(`https://creacionesperla.com/api/talles-productos?Id_Colegio=${id}&sexo=${sexo}&Nombre_Producto=${nombreProducto}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
        
                // Almacenar tanto los talles como la descripción en el estado
                setSizes(prevSizes => ({
                    ...prevSizes,
                    [`${kitId}_${nombreProducto}`]: {
                        talles: data.talles,           // Guardar los talles
                        descripcion: data.descripcion  // Guardar la descripción
                    }
                }));
            } catch (error) {
                console.error('Error fetching product sizes:', error);
            }
        };  
    
        fetchKits();
    }, [id, sexo]);
    
    const handleSizeChange = (kitId, productName, size) => {
        setSelectedSizes(prevSizes => ({
            ...prevSizes,
            [`${kitId}_${productName}`]: size
        }));
    };

    const handleAddToCart = (kit) => {
        const selectedProductSizes = {};
        let allSizesSelected = true; // Bandera para verificar si todos los talles han sido seleccionados
    
        // Recorrer los productos del kit y obtener sus talles seleccionados
        for (let i = 0; i < 15; i++) {
            const nombreProducto = kit[`Nombre_Producto_${i + 1}`];
            if (nombreProducto) {
                const sizeKey = `${kit.Id_Kit}_${nombreProducto}`;
                const selectedSize = selectedSizes[sizeKey] || null;
    
                if (!selectedSize) {
                    allSizesSelected = false; // Si no hay talle seleccionado para este producto, marcar como falso
                }
    
                selectedProductSizes[nombreProducto] = selectedSize || 'nulo';
            }
        }
    
        // Si no se han seleccionado todos los talles, mostrar un mensaje y salir de la función
        if (!allSizesSelected) {
            alert('Por favor, seleccione un talle para cada prenda antes de agregar el kit al carrito.');
            return;
        }
    
        const kitData = {
            id: kit.Id_Kit,
            name: kit.Nombre_Kit,
            price: kit.Precio_Total || 0,
            quantity: 1,
            image: `${imageBasePath}${id}/${kit.Web_Image}.jpg`,
            talle: 'Kit', // Siempre 'nulo' para el kit en sí
            colegio: colegio,
            sexo: sexo,
            nivel: "nivel",
            kit_talles: selectedProductSizes // Pasar los talles seleccionados para los productos del kit
        };
    
        addcustomerType('uniformes escolares');
        addToCart(kitData.id, kitData.name, kitData.price,
            kitData.quantity, kitData.image, kitData.talle,
            kitData.colegio, kitData.sexo, kitData.nivel, kitData.kit_talles);
        alert('Kit añadido al carrito');
    };

    const handleQuantityChange = (type) => {
        setQuantity((prevQuantity) => {
            if (type === 'inc') {
                return prevQuantity + 1;
            } else if (type === 'dec' && prevQuantity > 1) {
                return prevQuantity - 1;
            }
            return prevQuantity;
        });
    };

    const getZonaName = (zona) => {
        if (zona.includes('1')) {
            return 'Lavalleja 2455 B° Alta Cordoba';
        } else if (zona.includes('2')) {
            return 'Lavalleja 2455 B° Alta Cordoba  -  Av. Colon 2660 B° Alto Alberdi';
        }
        return 'Desconocido'; // Caso por defecto si no coincide con ningún número
    };

    return (
        <div className="kits-products">
            <div className='guide-bar'>
                <Link to={`/escolar/`} onClick={() => window.scrollTo(0, 0)}><p>Colegios</p></Link><p>/</p><Link to={`/escolar/${id}`} onClick={() => window.scrollTo(0, 0)}><p>Niveles</p></Link>
            </div>
            <hr />
            <h3>Kits disponibles para la categoría {sexo === '3' ? 'Femenino' : 'Masculino'} en el colegio: {colegio}</h3>
            <div className={`kits-gallery`}>
                {kits.map((kit, index) => {
                    // Contar la cantidad de productos del kit
                    const cantidadProductos = Array.from({ length: 15 }).reduce((acc, _, i) => {
                        return kit[`Nombre_Producto_${i + 1}`] ? acc + 1 : acc;
                    }, 0);
    
                    return (
                        <div key={index} className="kit-container">
                            <p className='kit-name'>{kit.Nombre_Kit}</p>
                            <img src={`${imageBasePath}${id}/${kit.Web_Image}.jpg`} alt={kit.Nombre_Kit} />
                            <p className='precio-kit'><b>Precio con descuento: {kit.Precio_Total}</b></p>
                            <hr />
                            <p className='cantidad-kit'><b>Cantidad de productos incluida: {cantidadProductos}</b></p>
                            <hr />
                            <p className='kit-comment'><b>Contiene los siguientes productos:</b></p>
                            <table>
                                <thead>
                                    <tr>
                                        <th hidden>Id</th>
                                        <th>Descripcion</th>
                                        <th>Producto</th>
                                        <th>Talle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: 15 }, (_, i) => {
                                        const idProducto = kit[`Id_Prod_${i + 1}`];
                                        const nombreProducto = kit[`Nombre_Producto_${i + 1}`];
                                        const sizeData = sizes[`${kit.Id_Kit}_${nombreProducto}`];
                                        if (idProducto !== '0' && idProducto !== 0) {
                                            return (
                                                <tr key={`${kit.Id_Kit}_${idProducto}`}>
                                                    <td hidden>{idProducto}</td>
                                                    <td className='description'>
                                                        <img src={imageBasePathinfo} alt="Ver descripción" className='prod-information'
                                                            onClick={() => alert(sizeData?.descripcion || 'Sin descripción')} /> Ver descripción
                                                    </td>
                                                    <td>{nombreProducto}</td>
                                                    <td>
                                                        <select
                                                            value={selectedSizes[`${kit.Id_Kit}_${nombreProducto}`] || ''}
                                                            onChange={(e) => handleSizeChange(kit.Id_Kit, nombreProducto, e.target.value)}>
                                                            <option value="">Seleccione un talle</option>
                                                            {sizeData?.talles?.map((talle, idx) => (
                                                                <option key={idx} value={talle.Talle_Nombre}>{talle.Talle_Nombre}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null; // Devolver null si no hay producto válido
                                    })}
                                </tbody>
                            </table>
                            <button className="add-to-cart" onClick={() => handleAddToCart(kit)}>
                                Agregar al Carrito
                            </button>
                            <p className='p-unidades'>Unidades</p>
                            <div className="pro-qty">
                                <span className="dec qtybtn" onClick={() => handleQuantityChange('dec')}>-</span>
                                <input type="text" value={quantity} disabled />
                                <span className="inc qtybtn" onClick={() => handleQuantityChange('inc')}>+</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='location-container'>
                <p className='location'><b>Podes conseguir el uniforme del colegio {colegio} en:</b><br /> {getZonaName(zona)}</p>
            </div>
        </div>
    );
}

export default Kits;


