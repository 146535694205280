import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Company.css';
import Modal from '../Modal/Modal';

function Company() {
  const { name } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const imageBasePath = `https://creacionesperla.com/Assets/Empresas/${name}`;

  useEffect(() => {
    fetch(`https://creacionesperla.com/api/uniformesempresas/${name}`)
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, [name]);

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  // Función para manejar errores de carga y probar distintos formatos de imagen
  const handleImageError = (e) => {
    if (e.target.src.endsWith('.webp')) {
      // Si falla la carga de la imagen .webp, intenta con .jpg
      e.target.src = e.target.src.replace('.webp', '.jpg');
    } else if (e.target.src.endsWith('.jpg')) {
      // Si falla la carga de la imagen .jpg, intenta con .png
      e.target.src = e.target.src.replace('.jpg', '.png');
    }
  };

  return (
    <div className="company-container">
      <h2>{name}</h2>
      <div className="products-container">
        {products.map((product, index) => (
          <div 
            key={index} 
            className="product-card"
            onClick={() => openModal(product)}
          >
            <h3>{product.Nombre}</h3>
            <img 
              src={`${imageBasePath}/${product.Imagen}.webp`} 
              alt={product.Nombre} 
              className="product-image"
              loading="lazy"
              onError={handleImageError}
            />
          </div>
        ))}
      </div>
      {selectedProduct && (
        <Modal product={selectedProduct} onClose={closeModal} imageBasePath={imageBasePath} />
      )}
    </div>
  );
}

export default Company;



