/*Este componente NewCollections se utiliza para mostrar una lista de nuevas colecciones. 
El componente muestra un encabezado y una línea horizontal, seguidos por una lista de ítems renderizados mediante el componente Item. 
Cada ítem se construye a partir de los datos presentes en el array new_collection, 
y las propiedades correspondientes se pasan al componente Item para su visualización. */

import React, { useEffect, useState } from 'react';
import './NewCollections.css';
import School from '../School/School';

const NewCollections = () => {
  const [visibleSchools, setVisibleSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Indicador de carga
  const [error, setError] = useState(null); // Para manejar errores

  // Número de colegios a mostrar por página
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch('https://creacionesperla.com/api/schools');

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Verificar que la respuesta sea JSON
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Received content is not JSON");
        }

        const data = await response.json();
        const shuffledSchools = data.sort(() => 0.5 - Math.random());
        const selectedSchools = shuffledSchools.slice(0, itemsPerPage);

        setVisibleSchools(selectedSchools);
      } catch (error) {
        console.error('Error fetching schools:', error);
        setError(error.message);
      } finally {
        setIsLoading(false); // Termina la carga
      }
    };

    fetchSchools();
  }, []);

  if (isLoading) {
    return <div className='new-collections'>Cargando colegios...</div>;
  }

  if (error) {
    return <div className='new-collections'>Error al cargar los colegios: {error}</div>;
  }

  return (
    <div className='new-collections'>
      <h1>Algunos de nuestros colegios</h1>
      <hr />
      <div className="Schoolcategory-products">
        {Array.isArray(visibleSchools) && visibleSchools.map((school, i) => (
          <School key={i} id={school.id} name={school.name} imagen={school.imagen} />
        ))}
      </div>
    </div>
  );
};

export default NewCollections;

