/*
En React, el "contexto" se refiere a una característica que permite pasar datos a través del árbol de componentes sin tener que pasar props manualmente en cada nivel. 
Es útil cuando tienes datos que quieres hacer accesibles a muchos componentes en tu aplicación, especialmente cuando esos componentes están anidados en diferentes niveles de jerarquía.
*/

import React, { createContext, useState, useEffect } from "react";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState({});
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [interest, setInterest] = useState(0);
    const [customerType, setCustomerType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState({ method: 'Efectivo', interest: 0, id: 'efectivo' });
    const [deliveryMethod, setDeliveryMethod] = useState('Domicilio');
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [comments, setComments] = useState(''); // Estado para almacenar comentarios

    useEffect(() => {
        const totalAmount = getTotalCartAmount();
        setInterest(totalAmount * paymentMethod.interest);
    }, [cartItems, paymentMethod]);

    const addcustomerType = (customerTypeLabel) => {
        if (customerType !== customerTypeLabel) {
            // If the customer type has changed, clear the cart
            setCartItems({});
        }
        setCustomerType(customerTypeLabel); // Update the customer type label
    };

    const addToCart = (itemId, itemName, itemPrice, quantity, imagePath, talle, colegio, sexo, nivel, kit_talles) => {
        setCartItems((prev) => ({
            ...prev,
            [itemId]: {
                quantity: (prev[itemId]?.quantity || 0) + quantity,
                name: itemName,
                price: itemPrice,
                image: imagePath,
                talle: talle,
                colegio: colegio,
                sexo: sexo,
                nivel: nivel,
                kit_talles: kit_talles // Aquí almacenamos los talles de los productos en el kit
            }
        }));
    };

    const removeFromCart = (itemId) => {
        setCartItems((prev) => {
            const updatedItem = { ...prev[itemId], quantity: prev[itemId].quantity - 1 };
            if (updatedItem.quantity <= 0) {
                const { [itemId]: _, ...rest } = prev;
                return rest;
            }
            return { ...prev, [itemId]: updatedItem };
        });
    };

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
            if (cartItems[item].quantity > 0) {
                totalAmount += cartItems[item].price * cartItems[item].quantity;
            }
        }
        return totalAmount;
    };

    const getTotalCartItems = () => {
        let totalItem = 0;
        for (const item in cartItems) {
            if (cartItems[item].quantity > 0) {
                totalItem += cartItems[item].quantity;
            }
        }
        return totalItem;
    };

    // const getDeliveryCost = (deliveryMethod) => {
    //     return deliveryMethod === 'Domicilio' ? 700 : 0;
    // };

    useEffect(() => {
        const fetchDeliveryOptions = async () => {
            try {
                const response = await fetch('https://creacionesperla.com/api/envios');
                if (!response.ok) throw new Error('Error fetching delivery options');
                const data = await response.json();
                setDeliveryOptions(data);
            } catch (error) {
                console.error('Error fetching delivery options:', error);
                setDeliveryOptions([]); // Si hay error, opciones vacías
            }
        };
        fetchDeliveryOptions();
    }, []);

    // Obtener el costo de envío basado en el método
    const getDeliveryCost = (method) => {
        const option = deliveryOptions.find(opt => opt.Nombre_Envio === method);
        return option ? option.Costo_Envio : 0;
    };

    // Actualizar el costo de envío cuando cambie el método de entrega
    useEffect(() => {
        const cost = getDeliveryCost(deliveryMethod);
        setDeliveryCost(cost);
    }, [deliveryMethod, deliveryOptions]);

    // Función para actualizar comentarios
    const updateComments = (newComments) => {
        setComments(newComments);
    };

    // Función para obtener comentarios
    const getComments = () => {
        return comments;
    };

    const isCartEmpty = () => {
        return Object.keys(cartItems).length === 0;
    };

    const contextValue = {
        cartItems,
        addToCart,
        removeFromCart,
        getTotalCartAmount,
        getTotalCartItems,
        deliveryCost,
        setDeliveryCost,
        setCartItems,
        addcustomerType, 
        customerType,
        interest, 
        setInterest,
        paymentMethod, 
        setPaymentMethod,
        deliveryMethod, 
        setDeliveryMethod, 
        getDeliveryCost,
        deliveryOptions,
        comments,
        updateComments,
        getComments,
        isCartEmpty
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;



