import './Gender.css';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContex';

const Gender = () => {
    const {addToCart, addcustomerType} = useContext(ShopContext);
    const {id, nivel, sexo} = useParams();
    const [products, setProducts] = useState([]);
    const [selectedProductDetails, setSelectedProductDetails] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null); // State to track selected size
    const [quantity, setQuantity] = useState(1);
    const [blinkMessage, setBlinkMessage] = useState(false); // State to handle blink effect
    const imageBasePath = 'https://creacionesperla.com/Assets/Escuelas/';
    const [zona, setZona] = useState('');
    const [colegio, setColegio] = useState('');

    useEffect(() => {
        fetch(`https://creacionesperla.com/api/ubicacion-colegio/${id}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data.length > 0) {
              setZona(data[0].Zona);  // Accede a la propiedad 'Zona' del primer resultado
              setColegio(data[0].Nombre_Colegio);
            } else {
              setZona('No disponible'); // Manejar el caso cuando no haya resultados
              setZona('Nombre no disponible');
            }
          })
          .catch(error => console.error('Error fetching colegios:', error));
    }, [id]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://creacionesperla.com/api/products-categorized?Id_Colegio=${id}&nivel=${nivel}&sexo=${sexo}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const productsList = await response.json();
                setProducts(productsList);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [id, nivel, sexo]);

    const handleImageClick = async (product) => {
        try {
            const response = await fetch(`https://creacionesperla.com/api/product-detail?Id_Colegio=${id}&Web_image=${product.Web_image}&Nombre=${product.Nombre}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const productDetails = await response.json();
            setSelectedProductDetails(productDetails);
            setSelectedPrice(null);
            setSelectedSize(null); // Clear selected size when a new product is selected
        } catch (error) {
            console.error('Error fetching product detail:', error);
        }
    };

    const handleSizeButtonClick = (size, price) => {
        setSelectedSize(size); // Set selected size
        setSelectedPrice(price);
        setBlinkMessage(false); // Reset blink message when size is selected
    };

    const handleCloseModal = () => {
        setSelectedProductDetails([]);
        setSelectedPrice(null);
        setSelectedSize(null);
        setQuantity(1);
        setBlinkMessage(false); // Reset blink message state
    };

    const handleQuantityChange = (type) => {
        setQuantity((prevQuantity) => {
            if (type === 'inc') {
                return prevQuantity + 1;
            } else if (type === 'dec' && prevQuantity > 1) {
                return prevQuantity - 1;
            }
            return prevQuantity;
        });
    };

    const handleAddToCart = async () => {
        if (!selectedSize) {
            setBlinkMessage(true); // Activate blink message
            setTimeout(() => {
                setBlinkMessage(false); // Turn off blink message after 2 seconds
            }, 2000);
            return;
        }

        // Determinar el valor textual de Sexo
        let sexoLabel;
        switch (selectedProductDetails[0].Sexo) {
            case 1:
                sexoLabel = "Comun";
                break;
            case 2:
                sexoLabel = "Varon";
                break;
            case 3:
                sexoLabel = "Mujer";
                break;
            default:
                sexoLabel = "Desconocido"; // Manejar caso no especificado
        }

        addcustomerType('uniformes escolares');
        const imageSrc = await getImageSrc(`${id}/${selectedProductDetails[0].Web_image}`);
        addToCart(
            selectedProductDetails[0].Id_Producto,
            selectedProductDetails[0].Nombre,
            selectedPrice,
            quantity,
            imageSrc,
            selectedSize,
            colegio,
            sexoLabel, // Usar la etiqueta textual en lugar del valor numérico
            getNivelLabel(nivel)
        );
        handleCloseModal(); // Close modal after adding to cart
    };

    const getImageSrc = (path) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = `${imageBasePath}${path}.webp`;
            img.onload = () => resolve(`${imageBasePath}${path}.webp`);
            img.onerror = () => {
                img.src = `${imageBasePath}${path}.jpg`;
                img.onload = () => resolve(`${imageBasePath}${path}.jpg`);
                img.onerror = () => resolve(`${imageBasePath}${path}.png`);
            };
        });
    };

    const getNivelLabel = (nivel) => {
        switch (nivel) {
            case '2':
                return 'Jardín';
            case '3':
                return 'Primario';
            case '4':
                return 'Secundario';
            case '5':
                return 'Terciario';
            case '9':
                return 'Sala de 4';
            case '10':
                return 'Sala de 5';
            case '20':
                return 'Accesorios';
            case '21':
                return 'Insignias';
            default:
                return 'Desconocido';
        }
    };

    const getZonaName = (zona) => {
        if (zona.includes('1')) {
            return 'Lavalleja 2455 B° Alta Cordoba';
        } else if (zona.includes('2')) {
            return 'Lavalleja 2455 B° Alta Cordoba  -  Av. Colon 2660 B° Alto Alberdi';
        }
        return 'Desconocido'; // Caso por defecto si no coincide con ningún número
    };

    return (
        <div className="gender">
            <div className='guide-bar'>
            <Link to={`/escolar/`} onClick={() => window.scrollTo(0, 0)}><p>Colegios</p></Link><p>/</p><Link to={`/escolar/${id}`} onClick={() => window.scrollTo(0, 0)}><p>Niveles</p></Link>
            </div>
            <hr />
            <h3>Productos disponibles para {getNivelLabel(nivel)} - {sexo === '3' ? 'Femenino' : 'Masculino'} en el colegio: {colegio}</h3>
            <div className={`product-gallery ${selectedProductDetails.length ? 'darken' : ''}`}>
                {products.map((product, index) => (
                    <div key={index} className="product-container" onClick={() => handleImageClick(product)}>
                        <p className='product-name'>{product.Nombre}</p>
                        <img 
                            src={`${imageBasePath}${id}/${product.Web_image}.webp`}
                            alt={product.Nombre}
                            onError={(e) => {
                                if (e.target.src.endsWith('.webp')) {
                                    e.target.src = `${imageBasePath}${id}/${product.Web_image}.jpg`;
                                } else if (e.target.src.endsWith('.jpg')) {
                                    e.target.src = `${imageBasePath}${id}/${product.Web_image}.png`;
                                }
                            }}
                        />
                        <p className='product-comment'>{product.Comentarios}</p>
                    </div>
                ))}
            </div>
            {selectedProductDetails.length > 0 && (
                <div className="product-modal">
                    <div className="product-modal-content">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <h2>{selectedProductDetails[0].Nombre}</h2>
                        <img 
                            src={`${imageBasePath}${id}/${selectedProductDetails[0].Web_image}.jpg`} 
                            alt={selectedProductDetails[0].Nombre}
                            onError={(e) => {
                                if (!e.target.src.includes('.png')) {
                                    e.target.src = `${imageBasePath}${id}/${selectedProductDetails[0].Web_image}.png`; // Cambia a .jpg solo si aún no se ha cambiado
                                    console.error(`Failed to load image: ${e.target.src}`); // Para depuración
                                }
                            }}
                        />
                        <p className='product-comment'>{selectedProductDetails[0].Comentarios}</p>
                        <p className={`product-price ${blinkMessage ? 'blink' : ''}`}>
                            {selectedPrice ? `Precio: ${selectedPrice}` : 'Seleccione un talle para ver el precio'}
                        </p>
                        <div className="product-sizes">
                            {selectedProductDetails.map((detail, index) => (
                                <button key={index} className={`product-size-button ${selectedSize === detail.Talle_Nombre ? 'selected' : ''}`} onClick={() => handleSizeButtonClick(detail.Talle_Nombre, detail.Precio_de_Venta)}>
                                    {detail.Talle_Nombre}
                                </button>
                            ))}
                        </div>
                        <button className="add-to-cart" disabled={!selectedSize} onClick={handleAddToCart}>
                            Agregar al Carrito
                        </button>
                        <p className='p-unidades'>Unidades</p>
                        <div className="pro-qty">
                            <span className="dec qtybtn" onClick={() => handleQuantityChange('dec')}>-</span>
                            <input type="text" value={quantity} disabled />
                            <span className="inc qtybtn" onClick={() => handleQuantityChange('inc')}>+</span>
                        </div>
                    </div>
                </div>
            )}
            <div className='location-container'>
                <p className='location'><b>Podes conseguir el uniforme del colegio {colegio} en:</b><br /> {getZonaName(zona)}</p>
            </div>
        </div>
    );
};

export default Gender;







