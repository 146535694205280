import React from 'react'
import Hero from '../Components/Hero/Hero';
//import Popular from '../Components/Popular/Popular';
import Popular3 from '../Components/Popular/Popular3.jsx';
//import Offers from '../Components/Offers/Offers';
import NewCollections from '../Components/NewCollections/NewCollections2';
import NewsLetter from '../Components/NewsLetter/NewsLetter';
import '../Pages/CSS/Shop.css'

const Shop = () => {
  return (
    <div>
      <Hero/>
      <Popular3/>
      <NewCollections/>
      <NewsLetter/>
    </div>
  )
}
export default Shop