import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo1.webp'
import instagram_icon from '../Assets/instagram_icon.webp'
import whatsapp_icon from '../Assets/whatsapp_icon.webp'
import facebook_icon from '../Assets/facebook_icon.webp'
import afip_icon from '../Assets/DATAWEB.webp'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={footer_logo} alt="" />
            <p>Creaciones Perla</p>
        </div>
        <ul className="footer-links">
            <li>Home</li>
            <li>Escolar</li>
            <li>Empresa</li>
            <li>Quienes somos</li>
            <li>Contacto</li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <a href="https://www.instagram.com/creaciones_perla/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram_icon} alt=""/>
                </a>
            </div>
            <div className="footer-icons-container">
                <a href="https://www.facebook.com/creaciones.perla" target="_blank" rel="noopener noreferrer">
                    <img src={facebook_icon} alt="" />
                </a>
            </div>
            <div className="footer-icons-container">
                <a href="https://api.whatsapp.com/send?phone=543515301676" target="_blank" rel="noopener noreferrer">
                    <img src={whatsapp_icon} alt="" />
                </a>
            </div>
        </div>
        <div className="afip-icon-container">
            <a href="http://qr.afip.gob.ar/?qr=6Q7O4kS444ph4pRteX-CQA,,">
                <img src={afip_icon} alt="" />
            </a>
        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright @ 2024 - Todos los derechos reservados</p>
            <p className='atribution-p'>Instagram icon by Pixel perfect</p>
            <p className='atribution-p'>Facebook icon by Freepik</p>
            <p className='atribution-p'>Whatsapp icon by riajulislam</p>
        </div>
    </div>
  )
}
export default Footer