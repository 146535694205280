import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './Niveles.css';

const Niveles = () => {
    const { id } = useParams(); // Recibe el ID desde la URL
    const imageBasePath = 'https://creacionesperla.com/Assets/Escuelas/';
    const [images, setImages] = useState([]);
    const [zona, setZona] = useState('');
    const [colegio, setColegio] = useState('');

    useEffect(() => {
        fetch(`https://creacionesperla.com/api/ubicacion-colegio/${id}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data.length > 0) {
              setZona(data[0].Zona);  // Accede a la propiedad 'Zona' del primer resultado
              setColegio(data[0].Nombre_Colegio);
            } else {
              setZona('No disponible'); // Manejar el caso cuando no haya resultados
              setZona('Nombre no disponible');
            }
          })
          .catch(error => console.error('Error fetching colegios:', error));
    }, [id]);

    useEffect(() => {
        // Recuperar la lista de imágenes de la carpeta específica
        const fetchImages = async () => {
            try {
                //const response = await fetch(`${imageBasePath}${id}`);
                const response = await fetch(`https://creacionesperla.com/api/imagenes/${id}`);//para version web
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const imageList = await response.json();
                setImages(imageList);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [id]);

    // Función para determinar el nombre del nivel basado en el número que se encuentra en el nombre del archivo
    const getNivelName = (fileName) => {
        if (fileName.includes('3')) {
            return 'Primario';
        } else if (fileName.includes('4')) {
            return 'Secundario';
        } else if (fileName.includes('5')) {
            return 'Terciario';
        } else if (fileName.includes('9')) {
            return 'Sala de 4';
        } else if (fileName.includes('10')) {
            return 'Sala de 5';
        } else if (fileName.includes('20')) {
            return 'Accesorios';
        } else if (fileName.includes('21')) {
            return 'Insignias';
        } else if (fileName.includes('kit')) {
            return 'Kits';
        } else if (fileName.includes('2')) {
            return 'Jardin';
        }
        return 'Desconocido'; // Caso por defecto si no coincide con ningún número
    };

    const getZonaName = (zona) => {
        if (zona.includes('1')) {
            return 'Lavalleja 2455 B° Alta Cordoba';
        } else if (zona.includes('2')) {
            return 'Lavalleja 2455 B° Alta Cordoba  -  Av. Colon 2660 B° Alto Alberdi';
        }
        return 'Desconocido'; // Caso por defecto si no coincide con ningún número
    };

    return (
        <div className='niveles'>
            <Link to={`/escolar/`} onClick={() => window.scrollTo(0, 0)}><p>Colegios</p></Link>
            <hr />
            <h3>Niveles disponibles para el colegio {colegio}:</h3>
            <div className="image-gallery">
                {images.map((img, index) => {
                    const nivel = getNivelName(img);
                    const imgNameWithoutExtension = img.split('.')[0]; // Quitar la extensión del archivo

                    // Controlar el renderizado de Kits para que solo se renderice una vez
                    if (nivel === 'Kits') {
                        return (
                        <div key={index} className="image-container">
                            <p>Ofertas - Kit de productos</p>
                            <img src={`${imageBasePath}${id}/${img}`} alt={`Imagen ${index}`} loading="lazy" />
                            <div className="hover-box mujer">
                                <Link to={`/escolar/${id}/${imgNameWithoutExtension}/3`} onClick={() => window.scrollTo(0, 0)}>
                                    <p>Femenino</p>
                                </Link>
                            </div>
                            <div className="hover-box varon">
                                <Link to={`/escolar/${id}/${imgNameWithoutExtension}/2`} onClick={() => window.scrollTo(0, 0)}>
                                    <p>Masculino</p>
                                </Link>
                            </div>
                        </div>
                        );
                    } else{
                        return (
                        <div key={index} className="image-container">
                            <p>{nivel}</p>
                            <img src={`${imageBasePath}${id}/${img}`} alt={`Imagen ${index}`} />
                            <div className="hover-box mujer">
                                <Link to={`/escolar/${id}/${imgNameWithoutExtension}/3`} onClick={() => window.scrollTo(0, 0)}>
                                    <p>Femenino</p>
                                </Link>
                            </div>
                            <div className="hover-box varon">
                                <Link to={`/escolar/${id}/${imgNameWithoutExtension}/2`} onClick={() => window.scrollTo(0, 0)}>
                                    <p>Masculino</p>
                                </Link>
                            </div>
                        </div>
                        );
                    }
                })}
            </div>
            <div className='location-container'>
                <p className='location'><b>Podes conseguir el uniforme del colegio {colegio} en:</b><br /> {getZonaName(zona)}</p>
            </div>
        </div>
    );
};

export default Niveles;







