import React, { useContext, useState } from 'react';
import './CartForm.css';
import { ShopContext } from '../../Context/ShopContex';
import emailjs from 'emailjs-com';
import moment from 'moment-timezone';


export const CartForm = () => {
    const { cartItems, getTotalCartAmount, deliveryCost, paymentMethod, interest, comments } = useContext(ShopContext);
    const [customerInfo, setCustomerInfo] = useState({
        name: '',
        surname: '',
        IDNumber: '',
        email: '',
        telephone: '',
        neighborhood: '',
        address: '',
        number: '',
        floor: '',
        department: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo({
            ...customerInfo,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const timestamp = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'); // Genera el timestamp en la zona horaria de Argentina
        const estado_pedido = "No Entregado"
    
        // Obtener información del dispositivo
        const deviceInfo = {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            vendor: navigator.vendor,
            language: navigator.language
        };

        // Función para consultar productos de un kit desde la base de datos
        const fetchKitProducts = async (kitId) => {
            try {
                console.log('Fetching products for kit:', kitId);  // Debugging
                const response = await fetch(`https://creacionesperla.com/api/kits-products-cart?kitId=${kitId}`);
                if (response.ok) {
                    const productsData = await response.json();
                    console.log('Kit products (raw):', productsData);  // Debugging
        
                    // Acceder al primer objeto dentro del array
                    const productObj = productsData[0]; // Accedemos al primer objeto del array
                    console.log('Product object:', productObj);
        
                    // Transformar el formato de datos en una lista de productos
                    const products = [];
                    for (let i = 1; i <= 15; i++) { // Asumiendo que hay hasta 15 productos
                        const idprod = productObj[`Id_Prod_${i}`];
                        const nombre = productObj[`Nombre_Producto_${i}`];
                        const precio = productObj[`Precio_Producto_${i}`];
                        const talle = productObj[`Talle_Producto_${i}`];
                        const nivel = productObj[`Nivel_Producto_${i}`];
                        const sexo = productObj[`Sexo_Producto_${i}`];
        
                        // Solo agregar productos que tengan un nombre válido
                        if (nombre && nombre.trim() !== "") {
                            products.push({
                                id: idprod, // Usar el índice como ID, ajusta según tus necesidades
                                name: nombre,
                                price: parseFloat(precio),
                                talle: talle || 'nulo', // Si no tiene talle, usar 'nulo'
                                nivel: nivel,
                                sexo: sexo
                            });
                        }
                    }
        
                    console.log('Transformed products:', products);  // Verificar la transformación
                    return products;
        
                } else {
                    console.error(`Error al obtener productos del kit. Status: ${response.status}, Text: ${response.statusText}`);
                    return [];
                }
            } catch (error) {
                console.error('Error al realizar la consulta:', error);
                return [];
            }
        };        

        // Obtener información de productos del carrito
        let orderDetails = [];
        for (const [itemId, item] of Object.entries(cartItems)) {
            // Si el producto es un kit (talle === 'Kit'), obtener los productos del kit
            if (item.talle === 'Kit') {
                const kitProducts = await fetchKitProducts(itemId);
                if (kitProducts.length > 0) {
                    kitProducts.forEach((product) => {
                        const productTalle = item.kit_talles[product.name] || 'nulo'; // Obtener el talle correspondiente del kit_talles
                        orderDetails.push({
                            itemId: product.id, // Usar el ID del producto individual del kit
                            Nombre: product.name,
                            Precio: product.price,
                            Cantidad: item.quantity, // Cantidad del kit original
                            Talle: productTalle, // Usar el talle asignado desde kit_talles
                            Colegio: item.colegio,
                            Sexo: product.sexo,
                            Nivel: product.nivel,
                            Estado: "Entregado"
                        });
                    });
                }
            } else {
                // Si no es un kit, agregarlo directamente
                orderDetails.push({
                    itemId,
                    Nombre: item.name,
                    Precio: item.price,
                    Cantidad: item.quantity,
                    Talle: item.talle,
                    Colegio: item.colegio,
                    Sexo: item.sexo,
                    Nivel: item.nivel,
                    Estado: "Entregado"
                });
            }
        }

        const data = {
            customerInfo,
            paymentMethod, 
            totalAmount: getTotalCartAmount(),
            deliveryCost,
            totalWithDelivery: getTotalCartAmount() + deliveryCost,
            interest,
            timestamp,
            deviceInfo,
            orderDetails,
            totalWithInterest:getTotalCartAmount()+interest,
            comments,
            estado_pedido
        };

    
        try {
            const response = await fetch('https://creacionesperla.com/api/save-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                const resData = await response.json(); // Obtener la respuesta JSON y asignarla a resData
                alert('Gracias por su pedido. Por favor, verifique que ha recibido el email con su orden. Revise su casilla de spam en caso de no encontrarlo');
                window.location.href = '/'; // Redirigir a la página principal
            } else {
                const errorData = await response.json();
                alert(`Error desde la base de datos: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al finalizar la compra');
        }
    };
    
    // Definir la función para enviar el correo electrónico
    const sendEmailJS = (orderData) => {
        const orderDetails = orderData.orderDetails.map(item => ({
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            talle: item.talle
        }));
        console.log('Sending email with order data:', orderData); // Agrega este log

        emailjs.send(
            'service_qra1qsg', // Reemplazar con tu Service ID
            'template_pwicv4q', // Reemplazar con tu Template ID
            {
                to_email: orderData.customerInfo.email,
                customer_name: orderData.customerInfo.name,
                customer_surname: orderData.customerInfo.surname,
                customer_IDNumber: orderData.customerInfo.IDNumber,
                customer_email: orderData.customerInfo.email,
                customer_telephone: orderData.customerInfo.telephone,
                customer_neighborhood: orderData.customerInfo.neighborhood,
                customer_address: orderData.customerInfo.address,
                customer_number: orderData.customerInfo.number,
                customer_floor: orderData.customerInfo.floor,
                customer_department: orderData.customerInfo.department,
                order_details: orderDetails,
                payment_method: orderData.paymentMethod.method,
                interest: orderData.interest.toFixed(2),
                totalAmount: orderData.totalAmount,
                deliveryCost: orderData.deliveryCost,
                totalWithInterest: orderData.totalWithInterest,
                timestamp: orderData.timestamp,
                comments: orderData.comments,
                order_id: orderData.orderId
            },
            'QdmSJ4g6gkNPwyfVT' // Reemplazar con tu Public Key
        ).then(() => {
            console.log('Message sent successfully!');
            alert('Mensaje enviado exitosamente!');
        }, (error) => {
            console.error('Message not sent (Service Error):', error);
            alert('Hubo un problema para enviar el comprobante');
        });
    };
    
    
    return (
        <div className='cartitems'>
            <div className='customer-payment-row'>
                <form className='customer-form' onSubmit={handleSubmit}>
                    <h2>Tus datos de contacto</h2>
                    <div className='customer-form-row'>
                        <div>
                            <label htmlFor="name">Nombre*</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={customerInfo.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="surname">Apellido*</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                value={customerInfo.surname}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='customer-form-row'>
                        <div>
                            <label htmlFor="IDNumber">DNI*</label>
                            <input
                                type="text"
                                id="IDNumber"
                                name="IDNumber"
                                value={customerInfo.IDNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email*</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={customerInfo.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='customer-form-row'>
                        <div>
                            <label htmlFor="telephone">Telefono de contacto*</label>
                            <input
                                type="text"
                                id="telephone"
                                name="telephone"
                                value={customerInfo.telephone}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="neighborhood">Barrio*</label>
                            <input
                                type="text"
                                id="neighborhood"
                                name="neighborhood"
                                value={customerInfo.neighborhood}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='customer-form-row'>
                        <div>
                            <label htmlFor="address">Dirección*</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={customerInfo.address}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='customer-form-row'>
                        <div>
                            <label htmlFor="number">Nro</label>
                            <input
                                type="number"
                                id="number"
                                name="number"
                                value={customerInfo.number}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="floor">Piso</label>
                            <input
                                type="text"
                                id="floor"
                                name="floor"
                                value={customerInfo.floor}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="department">Departamento</label>
                            <input
                                type="text"
                                id="department"
                                name="department"
                                value={customerInfo.department}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='cart-total'>
                        <h2>Resumen de tu pedido</h2>
                        <div className='cart-total-item'>
                            <p className='cart-total-p-title'>Subtotal</p>
                            <hr />
                            <p className='cart-total-p-text'>${getTotalCartAmount()}</p>
                        </div>
                        <div className='cart-total-item'>
                            <p className='cart-total-p-title'>Costo de envío</p>
                            <hr />
                            <p className='cart-total-p-text'>${deliveryCost}</p>
                        </div>
                        <div className='cart-total-item'>
                            <p className='cart-total-p-title'>Intereses por pago con {paymentMethod.method}</p>
                            <hr />
                            <p className='cart-total-p-text'>${interest.toFixed(2)}</p>
                        </div>
                        <div className='cart-total-item'>
                            <h3>Total (prendas + intereses)</h3>
                            <h3>${(getTotalCartAmount() + interest).toFixed(2)}</h3>
                        </div>
                        <button type="submit">Finalizar Compra</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CartForm;





