import React, { useEffect, useState } from 'react';
import './CSS/Schools.css';
import School from '../Components/School/School.jsx';
import { useNavigate } from 'react-router-dom';

const Schools = (props) => {
  const [schools, setSchools] = useState([]);
  const [visibleSchools, setVisibleSchools] = useState([]);
  const navigate = useNavigate();

  // Number of schools to display per page
  const itemsPerPage = 65;

  useEffect(() => {
    fetch('https://creacionesperla.com/api/schools') // pedido para server.js
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setSchools(data);
        setVisibleSchools(data.slice(0, itemsPerPage)); // Show first page
      })
      .catch(error => console.error('Error fetching schools:', error));
  }, []);

  const handleSchoolSelect = (event) => {
    const selectedSchoolId = event.target.value;
    if (selectedSchoolId) {
      navigate(`/escolar/${selectedSchoolId}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className='School-category'>
      <img className='Schoolcategory-banner' src={props.banner} alt="" />
      <div className="Schoolcategory-indexSort">
        <p>
           <span>Mostrando elementos del 1 al {visibleSchools.length}</span> Total = {schools.length} colegios
        </p>
        <div className="Schoolcategory-sort">
          <label htmlFor="school-dropdown">Seleccione su colegio: </label>
          <select id="school-dropdown" onChange={handleSchoolSelect}>
            <option value="">Seleccione un colegio</option>
            {schools.map(school => (
              <option key={school.id} value={school.id}>
                {school.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="Schoolcategory-products">
        {Array.isArray(visibleSchools) && visibleSchools.map((school, i) => (
          <School key={i} id={school.id} name={school.name} imagen={school.imagen} />
        ))}
      </div>
    </div>
  );
};

export default Schools;



