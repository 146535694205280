import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importa Font Awesome
import './Contacto.css';

// Crea un ícono personalizado usando Font Awesome
const customIcon = L.divIcon({
  className: 'custom-icon',
  html: `<i class="fas fa-map-marker-alt"></i>`, // Usa el ícono de Font Awesome
  iconSize: [32, 32], // Tamaño del ícono
  iconAnchor: [16, 32], // Ancla del ícono
  popupAnchor: [0, -32] // Posición del popup respecto al ícono
});

function LocationMap({ position }) {
  const map = useMap();
  if (position) {
    map.setView(position, 15, { animate: true });
  }
  return null;
}

function Contacto() {
  const [selectedPosition, setSelectedPosition] = useState(null);

  const center = [-31.3895049, -64.2105008];
  const marker1 = [-31.38699099300633, -64.18258256981345];
  const marker2 = [-31.403612543617978, -64.21667111225421];

  return (
    <div className="contacto-container">
      <div className="column-container">
        <div className="info-cards">
          <div className="card" onClick={() => setSelectedPosition(marker1)}>
            <h3>Creaciones Perla - Casa Central</h3>
            <p><strong>Dirección:</strong> Juan Antonio Lavalleja 2455</p>
            <p><strong>Barrio:</strong> Alta Córdoba</p>
            <p><strong>Lunes a Viernes:</strong> 8:00 a 12:30 // 15:30 a 19:30</p>
            <p><strong>Sábados:</strong> 9:00 a 13:00</p>
            <p><strong>Teléfono:</strong> 0351-4742085</p>
          </div>
          <div className="card" onClick={() => setSelectedPosition(marker2)}>
            <h3>Creaciones Perla - Sede Colón</h3>
            <p><strong>Dirección:</strong> Av. Colón 2660</p>
            <p><strong>Barrio:</strong> Alto Alberdi</p>
            <p><strong>Lunes a Viernes:</strong> 8:00 a 12:30 // 15:30 a 19:30</p>
            <p><strong>Sábados:</strong> 9:00 a 13:00</p>
            <p><strong>Teléfono:</strong> +54-9-3517-288932</p>
          </div>
        </div>
        <div className="map-and-cards">
          <MapContainer center={center} zoom={13} style={{ width: '100%', height: '300px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMap position={selectedPosition} />
            <Marker position={marker1} icon={customIcon}>
              <Popup>
                <strong>Creaciones Perla - Casa Central</strong><br />
                Dirección: Juan Antonio Lavalleja 2455<br />
                Barrio: Alta Córdoba<br />
                Lunes a Viernes: 8:00 a 12:30 // 15:30 a 19:30<br />
                Sábados: 9:00 a 13:00<br />
                Tel: 0351-4741837
              </Popup>
            </Marker>
            <Marker position={marker2} icon={customIcon}>
              <Popup>
                <strong>Creaciones Perla - Sede Colón</strong><br />
                Dirección: Av. Colón 2660<br />
                Barrio: Alto Alberdi<br />
                Lunes a Viernes: 8:00 a 12:30 // 15:30 a 19:30<br />
                Sábados: 9:00 a 13:00<br />
                Tel: +54-9-3517-288932
              </Popup>
            </Marker>
          </MapContainer>
          <div className="additional-cards">
            <div className="card">
              <h3>Teléfono general (solo Whatsapp)</h3>
              <p>+54 351 5301676</p>
            </div>
            <div className="card">
              <h3>Correo Electrónico general</h3>
              <p>contacto@creacionesperla.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;









